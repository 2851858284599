import * as React from "react"
import NavbarComp from "../components/NavbarComp.js"

// markup
const IndexPage = () => {
  return (
    <NavbarComp />
  )
}

export default IndexPage
